import shopifyGenericDefaults from '../shopify-generic/config.js';

const ignoreFields = [
  ...shopifyGenericDefaults.Widgets.find((w) => w.name === 'Facets').ignoreFields,
  'category',
  'Fitment',
];

shopifyGenericDefaults.Widgets.forEach((w) => {
  if (w.type === 'FacetPanel' || w.name === 'FacetDialog') {
    w.ignoreFields = ignoreFields;
  }
});

const baseUpdateCallback = shopifyGenericDefaults.Widgets.find(
  (w) => w.name === 'SearchResult',
)?.updateCallback;

function updateCallback() {
  baseUpdateCallback?.();

  new window.theme.QuickAdd(document.querySelector('.cm_SearchResult'));
}

export default {
  includes: ['shopify-generic'],
  ...shopifyGenericDefaults,
  facets: {
    ...shopifyGenericDefaults.facets,
    rangedFacet: [
      // make wheel_offset not a slider
      ...shopifyGenericDefaults.facets.rangedFacet
        .map((facet) => {
          if (!facet.fields.includes('wheel_offset')) {
            return facet;
          }
          const newFields = facet.fields.filter((field) => field !== 'wheel_offset');
          return newFields.length > 0 ? { ...facet, fields: newFields } : null;
        })
        .filter(Boolean),
    ],
  },
  Widgets: [
    ...shopifyGenericDefaults.Widgets.filter((w) => !['SearchResult'].includes(w.name)),
    {
      name: 'SearchResult',
      infiniteScrollDisabled: !!window.Convermax.config?.infiniteScrollDisabled,
      updateCallback,
    },
  ],
};
